import { EnumValues } from "./enums.js";

/**
 * ONLY internal endpoints and support actions from our 'Platform Admin' dashboard.
 * If a capability is shared for org and platform admins, please use EDIT_ORG instead.
 */
const PlatformAdminOnlyCaps = {
  VIEW_PLATFORM_ADMIN: "VIEW_PLATFORM_ADMIN",
  EDIT_PLATFORM_ADMIN: "EDIT_PLATFORM_ADMIN",
} as const;

const OrgCaps = {
  /**
   * Edit capability granted to both org admins and platform admins
   */
  EDIT_ORG_ADMIN_OR_PLATFORM_ADMIN: "EDIT_ORG_ADMIN_OR_PLATFORM_ADMIN",
  /**
   * View capability granted to both org admins and platform admins
   */
  VIEW_ORG_ADMIN_OR_PLATFORM_ADMIN: "VIEW_ORG_ADMIN_OR_PLATFORM_ADMIN",
  /**
   * Administrative actions that can be taken on an org,
   * either by an org admin or a platform admin.
   */
  EDIT_ORG_ADMIN_ONLY: "EDIT_ORG_ADMIN_ONLY",
  /**
   * The ability to view an org's settings information.
   *
   * This capability is only granted to org admins.
   */
  VIEW_ORG_ADMIN_ONLY: "VIEW_ORG_ADMIN_ONLY",
  /**
   * The ability to view an org as a full member of the org.
   *
   * This does NOT include guests and anonymous clients.
   */
  VIEW_ORG_INTERNAL: "VIEW_ORG_INTERNAL",
  /**
   * The ability to view an org's internal settings and metadata information.
   *
   * This includes Org members and guests today, but does not include null clients or anonymous users.
   */
  VIEW_ORG_EXTERNAL: "VIEW_ORG_EXTERNAL",
  /**
   * The ability to view an org's non-sensitive details (category, color palette, etc)
   *
   * Anonymous clients are included, but null clients are not.
   */
  VIEW_ORG_PUBLIC: "VIEW_ORG_PUBLIC",
  /**
   * Includes any client type, and null clients.
   *
   * Sometimes, this may not relate directly to an org. An example would be a 'promo', which is not just for a specific org.
   */
  VIEW_GLOBAL_PUBLIC: "VIEW_GLOBAL_PUBLIC",
  /**
   * The ability to view an org's slack channel information.
   */
  VIEW_ORG_SLACK_CHANNELS: "VIEW_ORG_SLACK_CHANNELS",
  /**
   * Specific cability granted to privileged users on EPD-REALITY to use Spellgrounds and internal, experimentation endpoints.
   *
   * This capability is only granted to users within the HEX or HEX-TESTING workspaces and when the api client's `enableInternalMagicExperimentation` is true.
   */
  USE_MAGIC_INTERNAL_ENDPOINTS_FOR_EXPERIMENTATION:
    "USE_MAGIC_INTERNAL_ENDPOINTS_FOR_EXPERIMENTATION",
  /**
   * The ability to use Hex's internal-only endpoints that shouldn't be exposed to customers.
   *
   * The capability is only granted on `hex` and `hex-testing`.
   */
  USE_HEX_INTERNAL_ENDPOINTS: "USE_HEX_INTERNAL_ENDPOINTS",
  /**
   * The ability to use Hex's internal-only endpoints on a particular project
   */
  USE_HEX_INTERNAL_ENDPOINTS_ON_PROJECT:
    "USE_HEX_INTERNAL_ENDPOINTS_ON_PROJECT",
  /**
   * The ability to upload files within an org. Can be disabled at the workspace-level via the org.allowFileUploads field.
   * This lives at the org-level because it impacts both project and app_session uploads as well as the creation of external
   * file integrations, and could be expanded to other use-cases in the future.
   */
  UPLOAD_FILES: "UPLOAD_FILES",

  /**
   * The ability to apply a status to another object within an org.
   */
  APPLY_STATUS: "APPLY_STATUS",
} as const;

const AppSessionCaps = {
  /**
   * Capability granted if the client is allowed to view the app session.
   */
  VIEW_APP_SESSION: "VIEW_APP_SESSION",
  /**
   * Capability granted if the app session is editable; as an example, app sessions that are from scheduled runs or snapshots are not editable.
   */
  EDIT_APP_SESSION: "EDIT_APP_SESSION",
  /**
   * Capability granted if the app session metadata is editable - this is less restrictive than EDIT_APP_SESSION and can include metadata updates to snapshots like `name`.
   */
  EDIT_APP_SESSION_METADATA: "EDIT_APP_SESSION_METADATA",
  /**
   * Capability granted if the app session is shareable; as an example, published app sessions for a Hex with OAuth without credential sharing are not shareable.
   */
  SHARE_APP_SESSION: "SHARE_APP_SESSION",
  /**
   * In order to accomplish 'least privilege', app session api clients are not allowed to edit the project contents.
   *
   * However, app session api clients still need to perform some HexVersion edit actions, such as editing the project files.
   *
   * This capability permits clients to edit the project files, but should not be used for other types of project edits.
   */
  EDIT_PROJECT_FILES: "EDIT_PROJECT_FILES",
  /**
   * Capability granted if the app session is deletable
   */
  DELETE_APP_SESSION: "DELETE_APP_SESSION",
} as const;

/** All Semantic caps relevant to comments and comment reactions. */
const CommentCaps = {
  /**
   * Capability for whether or not the user can react to a comment.
   * Only logged in users should be able to create comment reactions, which is why this is a separated cap from viewing comments.
   */
  CREATE_COMMENT_REACTION: "CREATE_COMMENT_REACTION",
  /**
   * Capability for whether or not a comment is visible to the user.
   */
  VIEW_COMMENT_AND_REACTIONS: "VIEW_COMMENT_AND_REACTIONS",
  /**
   * Editing or deleting comments is only possible if the owner of the comment has the appropriate permissioning on the project app org logic view.
   */
  EDIT_OR_DELETE_COMMENT: "EDIT_OR_DELETE_COMMENT",
  /**
   * The owner of a comment, or a project editor, is allowed to resolve a comment.
   */
  RESOLVE_COMMENT: "RESOLVE_COMMENT",
  /**
   * The owner of a comment or project editor can unresolve a comment if its current resolved.
   */
  UNRESOLVE_COMMENT: "UNRESOLVE_COMMENT",
  /**
   * Only logged in users for the org who have app+ access can leave a comment on the app.
   */
  CREATE_COMMENT_FOR_APP: "CREATE_COMMENT_FOR_APP",
  /**
   * Only logged in users for the org who have viewer+ access can leave a comment on the logic view.
   */
  CREATE_COMMENT_FOR_LOGIC: "CREATE_COMMENT_FOR_LOGIC",
  /**
   * Only the user who initially created the comment reaction can delete the comment reaction.
   */
  DELETE_COMMENT_REACTION: "DELETE_COMMENT_REACTION",
  /**
   * Action taken when a comment is deleted and the user wants to restore the comment.
   *
   * This action is only permissioned to the user who created the comment, by clicking ctrl+z or cmd+z.
   */
  RESTORE_COMMENT: "RESTORE_COMMENT",
} as const;

/** All Semantic Caps that relate to a Shared or project asset */
const SharedOrProjectAssetSemanticCaps = {
  /**
   * Granted to clients who can edit the data connection, or for app session clients that are responsible for running an app session with this data connection.
   *
   * This is a separate semantic cap from EDIT_OR_DELETE_DATA_CONNECTION because app sessions should never be able to edit a data connection.
   */
  VIEW_SENSITIVE_DATA_CONNECTION_DETAILS:
    "VIEW_SENSITIVE_DATA_CONNECTION_DETAILS",
  /**
   * View the sensitive secret value.
   */
  VIEW_SENSITIVE_SECRET_VALUE: "VIEW_SENSITIVE_SECRET_VALUE",
  /**
   * Can edit or delete a project or shared asset.
   */
  EDIT_OR_DELETE_ASSET: "EDIT_OR_DELETE_ASSET",
  /**
   * This capability is granted for a client that can edit certain asset metadata.
   * This is granted to any client that can import the asset and is a workspace Admin or Manager.
   * It's used to authorize edits for some types of metadata that is less sensitive
   * than requiring 'EDIT_OR_DELETE_ASSET' (ie Data Manager: status, categories, magic description, etc).
   */
  EDIT_ASSET_METADATA_FOR_WORKSPACE: "EDIT_ASSET_METADATA_FOR_WORKSPACE",
  /**
   * Can write to query cache for data connection asset.
   */
  WRITE_QUERY_CACHE: "WRITE_QUERY_CACHE",
  /**
   * Intended to be granted a project viewer who can view the asset details, except for the sensitive or secret information for the asset.
   *
   * Non sensitive asset details would include things like: the name of the asset, the data connection schema which is granted to viewers, etc.
   */
  VIEW_NON_SENSITIVE_ASSET_DETAILS: "VIEW_NON_SENSITIVE_ASSET_DETAILS",
  /**
   * Can import shared asset into project and write code using the asset.
   *
   * This should never be granted for a project asset.
   */
  IMPORT_SHARED_ASSET: "IMPORT_SHARED_ASSET",
  /**
   * Use this instead of IMPORT_ASSET when working with an asset that may or may not be a shared asset.
   *
   * We never will grant IMPORT_ASSET on a project level asset, so this cap represents using the asset
   * when writing new queries or code.
   */
  WRITE_QUERY_OR_CODE_USING_ASSET: "WRITE_QUERY_OR_CODE_USING_ASSET",
  /**
   *
   * Can view results derived from the shared asset (e.g. query results in app view, or view the results that used a secret)
   * If missing, cannot view projects or apps that use the shared asset.
   *
   */
  VIEW_RESULTS_USING_ASSET: "VIEW_RESULTS_USING_ASSET",
  /**
   * Can view the sensitive information and service config for the external file integration asset.
   */
  VIEW_SENSITIVE_EXTERNAL_FILE_INTEGRATION_SERVICE_CONFIG:
    "VIEW_SENSITIVE_EXTERNAL_FILE_INTEGRATION_SERVICE_CONFIG",
  /**
   * Can write back to the source of the external file integration.
   *
   * This is only permitted to app sessions that have the matching hex version for where the external file integration is being used.
   */
  WRITE_BACK_FOR_EXTERNAL_FILE_INTEGRATION:
    "WRITE_BACK_FOR_EXTERNAL_FILE_INTEGRATION",
  /**
   * This only applies to data connections. Can trigger a refresh of a data connection.
   *
   * For workspace connections - if a data connection has `adminOnlySchemaRefresh` set to true, then this should match EDIT_OR_DELETE_ASSET. Otherwise, this will match `IMPORT_SHARED_ASSET`.
   * For project connections - this will always match `EDIT_OR_DELETE_ASSET`
   */
  REFRESH_DATA_CONNECTION_SCHEMA: "REFRESH_DATA_CONNECTION_SCHEMA",
  /**
   * This only applies to data connections. Can import an external semantic layer that will connect to this data connection when used in Hex.
   *
   */
  IMPORT_SEMANTIC_LAYER: "IMPORT_SEMANTIC_LAYER",
} as const;

const HexShareCaps = {
  /**
   * If the editor of a project is also considered an owner of the project, they are allowed to share the project with another owner.
   */
  SHARE_PROJECT_WITH_OWNER: "SHARE_PROJECT_WITH_OWNER",
  /**
   * Granted to an editor of a project who is allowed to share the project with a non-owner.
   */
  SHARE_PROJECT_WITH_NON_OWNER: "SHARE_PROJECT_WITH_NON_OWNER",
  /**
   * Granted to an admin so that they can upgrade their own grant on a project.
   */
  UPGRADE_PROJECT_GRANT_FOR_SELF: "UPGRADE_PROJECT_GRANT_FOR_SELF",
} as const;

const HexCaps = {
  /**
   * Allows for clients to create projects within an org.
   */
  CREATE_PROJECT: "CREATE_PROJECT",
  /**
   * The capability granted for a client that is allowed to edit a Hex.
   */
  EDIT_PROJECT_CONTENTS: "EDIT_PROJECT_CONTENTS",
  /**
   * This capability is granted for a client that can edit certain Hex metadata.
   * This is granted to any client that can either edit the project contents _or_ is a workspace Admin or Manager
   * that is a viewer on the project. It's used to authorize edits for some types of Hex metadata that is less privileged
   * than requiring edit on the full project contents.
   */
  EDIT_PROJECT_METADATA_FOR_WORKSPACE: "EDIT_PROJECT_METADATA_FOR_WORKSPACE",
  /**
   * This capability is granted to someone who can view both the project logic and app.
   */
  VIEW_PROJECT_CONTENTS_FOR_LOGIC: "VIEW_PROJECT_CONTENTS_FOR_LOGIC",
  /**
   * This capability is intended for the app-only viewer of a project
   */
  VIEW_PROJECT_CONTENTS_FOR_APP: "VIEW_PROJECT_CONTENTS_FOR_APP",
  /**
   * This capability is intended to include the project metadata required to view the org admin's pages
   */
  VIEW_PROJECT_METADATA: "VIEW_PROJECT_METADATA",
  /**
   * If the editor of a project is also considered an owner of the project, they are allowed to share the project with another owner.
   */
  SHARE_PROJECT_WITH_OWNER: "SHARE_PROJECT_WITH_OWNER",
  /**
   * Granted to an editor of a project who is allowed to share the project with a non-owner.
   */
  SHARE_PROJECT_WITH_NON_OWNER: "SHARE_PROJECT_WITH_NON_OWNER",
  /**
   * Allows for api clients to create pre-signed urls for the context of embedding in an iframe for a page.
   */
  CREATE_PRESIGNED_EMBEDDING_URL: "CREATE_PRESIGNED_EMBEDDING_URL",
  /**
   * Granted to a user on a project who is a project owner and is able to:
   * - remove / add full owners
   * - decide whether or not the project can be used in Notion previews
   */
  EDIT_PROJECT_PERMISSIONS_SETTINGS: "EDIT_PROJECT_PERMISSIONS_SETTINGS",
  /**
   * Granted to an editor of a project who is allowed to share the project with a non-owner.
   * Also granted to workspce admins, and managers with view+ access to the project.
   *
   * This capability allows the user to both delete and restore the project.
   */
  DELETE_OR_RESTORE_PROJECT: "DELETE_OR_RESTORE_PROJECT",
  /**
   * Granted to project editors and admins to edit, delete, disable/enable project scheduled runs.
   * There is an admin specific page for managing all of these scheduled runs so its important to include admins in this role.
   */
  EDIT_PROJECT_SCHEDULED_RUNS: "EDIT_PROJECT_SCHEDULED_RUNS",
  /**
   * Granted to Explorers, Editors and Admins to view the scheduled runs for projects.
   *
   * Explorers should not, however, see all notifications for the scheduled runs: only the ones they have created.
   * */
  VIEW_PROJECT_SCHEDULED_RUNS: "VIEW_PROJECT_SCHEDULED_RUNS",
  /**
   * Granted to Explorers with "can view" and above to attach notifications to a project schedule.
   */
  CREATE_SCHEDULED_RUN_NOTIFICATION: "CREATE_SCHEDULED_RUN_NOTIFICATION",
  /**
   * Granted to Explorers with "Can view" that created the notification
   * and any user that can edit the project's scheduled runs.
   */
  VIEW_AND_EDIT_SCHEDULE_NOTIFICATION: "VIEW_AND_EDIT_SCHEDULE_NOTIFICATION",
  /**
   * Permitted to view the presence (views) on an app session or project.
   *
   * This can either be granted based on the Hex or app session.
   */
  VIEW_PROJECT_PRESENCE: "VIEW_PROJECT_PRESENCE",
  /**
   * The capability to view the project grants and who the project has been shared with.
   *
   * This is only permitted to project editors and admins.
   */
  VIEW_PROJECT_PERMISSIONS: "VIEW_PROJECT_PERMISSIONS",
  /**
   * Capability granted if the project file can be downloaded.
   *
   * A project file can be downloaded if its format is allowed and the client requesting can view the file.
   * This is different than just `VIEW_FOR_PROJECT_LOGIC` because we could get a file format that is disallowed.
   */
  DOWNLOAD_PROJECT_FILE: "DOWNLOAD_PROJECT_FILE",
  /**
   * The capability to explore form here within an org.
   */
  CREATE_EXPLORE_FROM_PROJECT_CELL: "CREATE_EXPLORE_FROM_PROJECT_CELL",
} as const;

/**
 * Capabilities that are granted to a project viewers+ or org admin.
 *
 * Any project viewers+ are permitted to view magic events, and org admins are permitted to see a subset of the magic event fields.
 */
const MagicEventCaps = {
  /**
   * View only a subset of magic event fields for the Admin portal and data manager.
   */
  VIEW_MAGIC_EVENT_FOR_ADMIN: "VIEW_MAGIC_EVENT_FOR_ADMIN",
  /**
   * Ability to edit the magic event
   */
  EDIT_MAGIC_EVENT: "EDIT_MAGIC_EVENT",
} as const;

const OAuthCaps = {
  /**
   * Whether or not the user can view the Snowflake/Databricks OAuth project connection.
   *
   * Any member of an org can view a project connection.
   */
  VIEW_ORG_SNOWFLAKE_OAUTH_PROJECT_CONNECTION:
    "VIEW_ORG_SNOWFLAKE_OAUTH_PROJECT_CONNECTION",
  VIEW_ORG_DATABRICKS_OAUTH_PROJECT_CONNECTION:
    "VIEW_ORG_DATABRICKS_OAUTH_PROJECT_CONNECTION",
  VIEW_ORG_BIGQUERY_OAUTH_PROJECT_CONNECTION:
    "VIEW_ORG_BIGQUERY_OAUTH_PROJECT_CONNECTION",
  /**
   * Editing the project connection is permissioned to any user who has view permissions on the Org.
   */
  CREATE_OR_EDIT_SNOWFLAKE_OAUTH_PROJECT_CONNECTION:
    "CREATE_OR_EDIT_SNOWFLAKE_OAUTH_PROJECT_CONNECTION",
  CREATE_OR_EDIT_DATABRICKS_OAUTH_PROJECT_CONNECTION:
    "CREATE_OR_EDIT_DATABRICKS_OAUTH_PROJECT_CONNECTION",
  CREATE_OR_EDIT_BIGQUERY_OAUTH_PROJECT_CONNECTION:
    "CREATE_OR_EDIT_BIGQUERY_OAUTH_PROJECT_CONNECTION",
  /**
   * Permission to edit the oauth configuration for a Hex version.
   *
   * Only admins and the project owner are allowed to do so.
   */
  EDIT_PROJECT_OAUTH_CONFIGURATION: "EDIT_PROJECT_OAUTH_CONFIGURATION",
} as const;

const CollectionCaps = {
  /**
   * Allows for clients to create collections within an org.
   */
  CREATE_COLLECTION: "CREATE_COLLECTION",
  /**
   * Allows for clients to view a collection within an org.
   *
   * Collection viewers include all org members and guests, if the grant is provided. Having this capability alone does not mean that
   * all of the projects in the collection are permitted to the client.
   */
  VIEW_COLLECTION: "VIEW_COLLECTION",
  /**
   * Collection managers can delete the collection, modify membership, or add/remove entities.
   *
   * Collection managers are only permitted to admins and editors in an org.
   */
  EDIT_COLLECTION: "EDIT_COLLECTION",
} as const;

const ReviewRequestCaps = {
  /**
   * Allows a client to create a review request for a given project
   */
  CREATE_REVIEW_REQUEST_FOR_PROJECT: "CREATE_REVIEW_REQUEST_FOR_PROJECT",
  /**
   * Allows a client to view all associated data for a review request
   */
  VIEW_REVIEW_REQUEST: "VIEW_REVIEW_REQUEST",
  /**
   * Allows a client to either close a review request or accept it
   */
  ACCEPT_OR_DISMISS_REVIEW_REQUEST: "ACCEPT_OR_DISMISS_REVIEW_REQUEST",
  /**
   * Allows a client to push new hex versions to a review request or edit meta data
   */
  UPDATE_REVIEW_REQUEST: "UPDATE_REVIEW_REQUEST",
  /**
   * Allows a client to add a review to a given review request
   */
  ADD_REVIEW_TO_REVIEW_REQUEST: "ADD_REVIEW_TO_REVIEW_REQUEST",
  /**
   * Update the status of a review request link for a specific user or group, used for approving or denying
   */
  UPDATE_REVIEW_REQUEST_LINK: "UPDATE_REVIEW_REQUEST_LINK",
  /**
   * Preserves the status of a review request link, but sets the "rerequested" field to true
   */
  RESET_REVIEW_REQUEST_LINK: "RESET_REVIEW_REQUEST_LINK",
  /**
   * Remove the review request link, only possible if there are no associated reviews
   */
  DELETE_REVIEW_REQUEST_LINK: "DELETE_REVIEW_REQUEST_LINK",
} as const;

export type SemanticCap = EnumValues<typeof SemanticCap>;

export const SemanticCap = {
  /**
   * Edit capabilities on a user entity, that should only be permitted to the user themselves in an org. An admin should not receive this capability.
   */
  EDIT_USER_SELF: "EDIT_USER_SELF",
  /**
   * View capability on a user entity, that should only be permitted to the user themselves
   */
  VIEW_USER_SELF: "VIEW_USER_SELF",
  /**
   * View either a user or group by ID.
   *
   * This is also permitted to anonymous and guest users, but only if the ID is supplied.
   * Its important to allow ID lookup for guests and anonymous users to see the Org user in a project that they are invited to.
   */
  VIEW_USER_OR_GROUP_BY_ID: "VIEW_USER_OR_GROUP_BY_ID",
  /**
   * This capability is granted to the user that is allowed to manage, delete, regenerate a user api client.
   *
   * Because we have several api client types, this semantic cap is specific to only user api clients.
   * This semantic cap also ensures that the proper org and feature gating settings are enabled.
   */
  MANAGE_USER_API_CLIENT: "MANAGE_USER_API_CLIENT",
  MANAGE_WORKSPACE_TOKEN_API_CLIENT: "MANAGE_WORKSPACE_TOKEN_API_CLIENT",
  ...CollectionCaps,
  ...OAuthCaps,
  ...OrgCaps,
  ...HexCaps,
  ...HexShareCaps,
  ...SharedOrProjectAssetSemanticCaps,
  ...CommentCaps,
  ...AppSessionCaps,
  ...PlatformAdminOnlyCaps,
  ...MagicEventCaps,
  ...ReviewRequestCaps,
} as const;

export type SemanticClientType = EnumValues<typeof SemanticClientType>;

export type SemanticCapSet = Record<SemanticClientType, boolean>;

export const SemanticClientType = {
  PLATFORM_ADMIN: "PLATFORM_ADMIN",
  HEX_SUPPORT_EMAIL: "HEX_SUPPORT_EMAIL",
  ORG_ADMIN: "ORG_ADMIN",
  ORG_MANAGER: "ORG_MANAGER",
  ORG_EDITOR: "ORG_EDITOR",
  ORG_EXPLORER: "ORG_EXPLORER",
  ORG_MEMBER: "ORG_MEMBER",
  ORG_GUEST: "ORG_GUEST",
  ORG_ANONYMOUS: "ORG_ANONYMOUS",
  API_CLIENT_USER: "API_CLIENT_USER",
  API_CLIENT_EMBEDDED_USER: "API_CLIENT_EMBEDDED_USER",
  API_CLIENT_NOTION: "API_CLIENT_NOTION",
  API_CLIENT_APP_SESSION: "API_CLIENT_APP_SESSION",
  API_CLIENT_SCREENSHOT_SERVICE: "API_CLIENT_SCREENSHOT_SERVICE",
  API_CLIENT_TABLE_PREVIEW: "API_CLIENT_TABLE_PREVIEW",
  API_CLIENT_WORKSPACE_TOKEN: "API_CLIENT_WORKSPACE_TOKEN",
  NULL_CLIENT: "NULL_CLIENT",
} as const;

export type StrictlyMatchesCaps<T extends SemanticCap> = {
  [K in T]: boolean;
} & {
  [K in Exclude<SemanticCap, T>]?: undefined;
};

export type RelativeCapsAreSuperset<T extends SemanticCap> = {
  [K in T]: boolean | CapsDerivedGloballyAndFromClientType;
};

/**
 * Sentinel Value that is used to indicate that the current rule does not impact the overall auth; instead, inherit caps from the global org/client checks and other client function.
 */
export const CapsDerivedGloballyAndFromClientType =
  "CapsDerivedGloballyAndFromClientType" as const;
export type CapsDerivedGloballyAndFromClientType =
  typeof CapsDerivedGloballyAndFromClientType;
