import { ProjectRole, Role } from "./enums";

export const PROJECT_ROLE_ORDERING = [
  ProjectRole.OWNER,
  ProjectRole.EDITOR,
  ProjectRole.VIEWER,
  ProjectRole.APP_USER,
];

export const ALL_PROJECT_ROLES = Object.values(ProjectRole);

export const isProjectRoleSuperset = (
  role: ProjectRole,
  targetRole: ProjectRole,
): boolean => {
  if (PROJECT_ROLE_ORDERING.indexOf(role) === -1) {
    console.error(`Invalid role ${role}`);
    return false;
  }

  return (
    PROJECT_ROLE_ORDERING.indexOf(role) <=
    PROJECT_ROLE_ORDERING.indexOf(targetRole)
  );
};

export const hasProjectRole = (
  roles: readonly ProjectRole[],
  targetRole: ProjectRole,
): boolean => {
  return roles.some((role) => isProjectRoleSuperset(role, targetRole));
};

export const maxProjectRole = (
  roles: readonly ProjectRole[],
): ProjectRole | undefined => {
  return sortProjectRoles(roles, "max_first")[0];
};

export const minProjectRole = (
  roles: readonly ProjectRole[],
): ProjectRole | undefined => {
  return sortProjectRoles(roles, "min_first")[0];
};

export function sortProjectRoles(
  roles: readonly ProjectRole[],
  direction: "min_first" | "max_first",
): readonly ProjectRole[] {
  const directionMultiplier = direction === "max_first" ? 1 : -1;
  return [...roles].sort(
    (a, b) =>
      directionMultiplier *
      (PROJECT_ROLE_ORDERING.indexOf(a) - PROJECT_ROLE_ORDERING.indexOf(b)),
  );
}

/**
 * lower bound inclusive, upper bound exclusive
 */
export function projectRoleBetweenBounds({
  lower,
  roleToCheck,
  upper,
}: {
  lower: ProjectRole;
  upper?: ProjectRole;
  roleToCheck: ProjectRole;
}): boolean {
  return (
    isProjectRoleSuperset(roleToCheck, lower) &&
    (upper == null ||
      (upper !== roleToCheck && isProjectRoleSuperset(upper, roleToCheck)))
  );
}

export const roleToProjectRole = {
  [Role.OWNER]: ProjectRole.OWNER,
  [Role.EDITOR]: ProjectRole.EDITOR,
  [Role.VIEWER]: ProjectRole.VIEWER,
  [Role.USER]: ProjectRole.APP_USER,
};

export const projectRoleToRole = {
  [ProjectRole.OWNER]: Role.OWNER,
  [ProjectRole.EDITOR]: Role.EDITOR,
  [ProjectRole.VIEWER]: Role.VIEWER,
  [ProjectRole.APP_USER]: Role.USER,
};

export const humanReadableProjectRole = (
  projectRole: ProjectRole,
  isComponent: boolean,
  explorerRoleCanViewChange: boolean,
): string =>
  ({
    [ProjectRole.OWNER]: "Full access",
    [ProjectRole.EDITOR]: "Can edit",
    [ProjectRole.VIEWER]: isComponent
      ? "Can import"
      : explorerRoleCanViewChange
        ? "Can explore"
        : "Can view",
    [ProjectRole.APP_USER]: explorerRoleCanViewChange ? "Can view" : "App only",
  })[projectRole];

/* TODO VELO-4256 Currently these are only being used for new nomenclature descriptions.
Delete this comment along with flag cleanup */
export const projectRoleDescription = (
  projectRole: ProjectRole,
  isComponent: boolean,
  explorerRoleCanViewChange: boolean,
): string =>
  ({
    [ProjectRole.OWNER]: "Full permissions to edit, share, rename, and delete.",
    [ProjectRole.EDITOR]: isComponent
      ? "Can view, comment, import, and edit. Can share, unless disabled by a 'Full access' user."
      : "Can view, comment, and edit. Can share, unless disabled by a 'Full access' user.",
    [ProjectRole.VIEWER]: isComponent
      ? "Can view, comment, and import. Cannot edit or share with others."
      : explorerRoleCanViewChange
        ? "Can view, explore, and comment. Cannot edit or share with others."
        : "Can view and comment. Cannot edit or share with others.",
    [ProjectRole.APP_USER]: "Can view and comment only on the published App.",
  })[projectRole];
